import React, { useState, useRef } from 'react'
import {
  LoadScript,
  GoogleMap,
  Polygon,
  Marker,
  DrawingManager,
} from '@react-google-maps/api'
import { SaveButton, MapView } from '../style'

let polygonPaths = []

const containerStyle = {
  width: '100%',
  height: '400px',
}

function Map({ returnPath, gpsData, isEdit }) {
  // Define defalut refs for Polygon
  let editPath = []
  let gpsValues = []
  let gpsCoverage

  if (gpsData.length > 0) {
    gpsCoverage = gpsValues
    editPath = gpsCoverage
  }
  console.log('gpsCoverage', gpsCoverage)

  const [path, setPath] = useState(editPath)
  const [newPath, setNewPath] = useState([])
  const [position, setPosition] = useState({
    lat: -0.17771232662548572,
    lng: -78.50285954952246,
  })
  const [selectedPlace, setSelectedPlace] = useState(null)
  const [markerMap, setMarkerMap] = useState({})

  const polygonRef = useRef(null)
  const listenersRef = useRef([])

  // useEffect(() => {
  //   debugger
  //   setPath(gpsCoverage)
  // }, [])

  // Call setPath with new edited path
  const onEdit = (ref, value) => e => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current.getPath()
      gpsCoverage[value] = nextPath
    }

    const shapeData = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    }

    path[value].latitude = shapeData.lat
    path[value].lng = shapeData.lng

    setPath(path)
    setSelectedPlace(shapeData)
    setPosition(position);
    console.log(selectedPlace)
  }

  //Deletes current shape in edit
  const onDelete = value => () => {
    const result = [
      path.slice(0, value).concat(path.slice(value + 1, path.length)),
    ]
    gpsCoverage = result.map(v => {
      return v
    })
    setPath(gpsCoverage[0])
  }

  // Bind refs to current Polygon and listeners
  const onLoad = polygon => {
    polygonRef.current = polygon
    const path = polygon.getPath()
    listenersRef.current.push(
      path.addListener('set_at', onEdit),
      path.addListener('insert_at', onEdit),
      path.addListener('remove_at', onEdit)
    )
  }

  // Call returnPath func to send polygon data to parent comonent
  const sendPath = () => {
    let data = []
    polygonPaths.forEach(values => {
      if (values.latLngs) {
        let d = values
          .getPath()
          .getArray()
          .map(latlng => {
            return { lat: latlng.lat(), lng: latlng.lng() }
          })
        d.latitude = values.latitude
        d.lng = values.length
        data.push(d)
      }
    })

    if (isEdit) {
      path.forEach(value => {
        data.push(value)
      })

      polygonPaths = []
    }

    setPath(data)
    returnPath(data)
  }

  // Clean up refs
  const onUnmount = () => {
    listenersRef.current.forEach(lis => lis.remove())
    polygonRef.current = null
  }

  // We have to create a mapping of our places to actual Marker objects
  const markerLoadHandler = (marker, place) => {
    console.log(markerMap)
    return setMarkerMap(prevState => {
      return { ...prevState, [place.id]: marker }
    })
  }

  const markerClickHandler = (event, place) => {
    console.log('EVENT --> ', event)
    console.log('PLACE --> ', place)
  }

  const onPolygonComplete = polygon => {
    let currentPolygon = newPath
    const polygonData = polygon
      .getPath()
      .getArray()
      .map(latLng => {
        return { lat: latLng.lat(), lng: latLng.lng() }
      })
    currentPolygon.push(polygonData)
    polygonPaths.push(polygon)

    setNewPath(currentPolygon)
  }

  const onMarkerComplete = marker => {
    const markerData = marker.getPosition()

    // .getPath()
    // .getArray()
    // .map(latlng =>{
    //   return { lat: latlng.lat(), lng: latlng.lng()}
    // })
    const data = { lat: markerData.lat(), lng: markerData.lng() }
    console.log('marker', data)
  }

  const onPolygonClick = e => {
    const shape = e.overlay

    shape.type = e.type
    window.google.maps.event.addListener(shape, 'mouseup', () => {
      const mapData = shape.getMap()
      console.log('map ', mapData)
    })

    window.google.maps.event.addListener(shape, 'rightclick', () => {
      delete shape.latLngs
      shape.setMap(null)
    })

    window.google.maps.event.addListener(shape, 'click', function(e) {
      let mapData = shape.getMap()
      var marker = new window.google.maps.Marker({
        title: 'Ubicación',
        map: mapData,
        draggable: false,
      })

      if (!shape.latitude && !shape.length) {
        const shapeData = {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        }
        shape.latitude = shapeData.lat
        shape.length = shapeData.lng
        marker.setPosition(shapeData)
      }

      marker.addListener('rightclick', function(e) {
        delete shape.latitude
        delete shape.length
        marker.setMap(null)
      })
    })
  }

  const markerRemoveHandler = (value, position, event) => {
    // delete shape.length
    delete path[value].latitude
    delete path[value].lng

    gpsCoverage = path
    setPath(gpsCoverage)
    setSelectedPlace(position)
  }

  const lib = ['drawing']
  return (
    <MapView>
      <React.Fragment>
        <LoadScript
          id="script-loader"
          googleMapsApiKey=""
          language="en"
          region="us"
          libraries={lib}
        >
          <GoogleMap
            id="map"
            mapContainerStyle={containerStyle}
            center={position}
            onCenterChanged
            zoom={12}
            onDblClick={false}
          >
            {path.map((value, index) => {
              let markerPosition = {}
              markerPosition = { lat: value.latitude, lng: value.lng }

              return (
                <React.Fragment>
                  <Polygon
                    key={`polygon-${index}`}
                    ref={polygonRef}
                    editable
                    draggable={false}
                    path={value}
                    onMouseUp={onEdit(polygonRef, index)}
                    onRightClick={onDelete(index)}
                    // onDragEnd={onEdit(index)}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                  />
                  {value.latitude && value.lng ? (
                    <Marker
                      key={`marker-${index}`}
                      position={markerPosition}
                      onLoad={marker =>
                        markerLoadHandler(marker, markerPosition)
                      }
                      onClick={event =>
                        markerClickHandler(event, markerPosition)
                      }
                      onRightClick={event =>
                        markerRemoveHandler(index, markerPosition, event)
                      }
                    />
                  ) : (
                    ''
                  )}
                </React.Fragment>
              )
            })}

            <DrawingManager
              onPolygonComplete={onPolygonComplete}
              onMarkerComplete={onMarkerComplete}
              onOverlayComplete={onPolygonClick}
              options={{
                drawingControl: true,
                drawingControlOptions: {
                  // position: window.google.maps.ControlPosition.TOP_CENTER,
                  drawingModes: ['polygon'],
                },
                polygonOptions: {
                  fillColor: `rgba(0, 0, 0, 0.37)`,
                  fillOpacity: 1,
                  strokeWeight: 4,
                  clickable: true,
                  editable: true,
                  draggable: false,
                  zIndex: 1,
                },
              }}
            />
          </GoogleMap>
        </LoadScript>
      </React.Fragment>
      <React.Fragment>
        <SaveButton color="primary" disabled={false} onClick={() => sendPath()}>
          Obtener coordenadas
        </SaveButton>
      </React.Fragment>
    </MapView>
  )
}

export default Map
