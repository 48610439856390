import styled from 'styled-components'

import Icon from '../../../components/Icon'

import sizes from '../../../styles/media'

export const PageTopSection = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;

  & a {
    width: 100%;
  }

  @media ${sizes.sm} {
    & a {
      width: auto;
    }
  }
`

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-around;
`

export const EyeIcon = styled(Icon)`
  color: #454545;
  font-size: 20px;
`

export const ShowItmesIcon = styled(Icon)`
  color: #18b0f7;
  font-size: 20px;
`
export const TableContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
export const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 20px;
`

export const TypeShipping = styled.span`
  margin-left: 5px;
  text-transform: uppercase;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: solid 0.25px #858585;
  padding: 5px;
`

export const ColumnTitle = styled.span`
  padding-top: 5px;
`

export const ColumnDescription = styled.span`
  color: #858585;
  padding-bottom: 5px;
`
